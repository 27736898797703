
.sc-header {
    background:#00c7d4 ; 
    color: rgb(255, 255, 255); 
    font-size: 18px;
  }

  .sc-message--content.sent .sc-message--text {
    color: white;
    background-color: #00c7d4;
    max-width: calc(100% - 120px);
    word-wrap: break-word;
    padding: 10px 20px;
  }    


  .sc-launcher {
    background:#00c7d4 ; 
  }

  .sc-chat-window {
    background:#F3F3F3 ; 
  }

  .sc-message-list {
    background:#F3F3F3; 
  }

  .sc-user-input {
    background-color: #FFFFFF; 
    border-radius: 8px; 
    margin: 10px 10px 10px 10px;    
    min-height: 60px;

  }

  .sc-user-input--text:empty:before {
    content: 'Pergunte algo referente ao estoque e vendas da farmácia';
  }

  /* .sc-header{
    min-height: 180px;
  } */

  .sc-header--img{
    background-color: #FFFFFF; 
    padding: 0px;
  }

.sc-message--avatar {
  background-image: url('https://toolspharma.com.br/wp-content/uploads/2025/01/Favicon-1-e1736965311556.png') !important;
}

.sc-message--content.received .sc-message--text {
  background-color:  #FFFFFF;
}