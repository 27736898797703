@tailwind base;
@tailwind components;
@tailwind utilities;

html,body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}

.home {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
}
.TituloDiv {
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #8898aa;
  font-weight: 600;
  margin-bottom: 0.1px;
  font-size: 14px;
  text-align: center;
  margin-top: 3%;
}

.ValorDiv {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-right: 0px;
  padding-right: 0px;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DetalhesDiv {
  font-family: 'Roboto', sans-serif;
  font-size: small;
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
}

.classFiltros {
  margin-top: -17rem;
}

.ContainerHead {

  display: flex;


  justify-content: space-around;



}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 50%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  display: flex;
}

.containerFiltro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input::-webkit-datetime-edit-fields-wrapper {
  text-decoration: underline;
}

.tagFiltro {
  background-color: #ffffff;
  border-radius: 0.8em;
  font-family: "Roboto", sans-serif;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 2em;
  padding-right: 2em;
  letter-spacing: 0.04em; 
  margin: 0.5em 0.1em 0.5em 0.5em;
  font-weight: 800;
  font-size: 0.9rem;
  color:#00C7D4;
  transition: background-color 0.3s, color 0.3s;


}

.tagFiltro:hover {
  background-color:#00C7D4;
  color: #fff;
}